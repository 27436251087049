import React from "react"
import Loadable from "@loadable/component"
const OwlCarousel = Loadable(() => import("react-owl-carousel"))

const ImageCarousel = ({ imageNodes }: { imageNodes: [any] }) => {
  return (
    <div className="container">
      <div className="col-lg-10 center-col">
        <OwlCarousel
          className="owl-theme listing-main-img margin-35px-bottom"
          loop
          margin={10}
          items={1}
          dots
          nav
          autoplay
          // autoHeight
        >
          {imageNodes.map(image => {
            return (
              <img
                key={image.node.relativePath}
                src={image.node.publicURL}
                className=" rounded-2xl"
              />
            )
          })}
        </OwlCarousel>
      </div>
    </div>
  )
}

export default ImageCarousel
