import React from "react"
import Layout from "../components/navigation/Layout"
import { graphql } from "gatsby"
import ImageCarousel from "../components/common/ImageCarousel"
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps"
import { MapTheme } from "../utils/MapTheme"

const PropertyMap = withGoogleMap(props => (
  <GoogleMap
    options={{ styles: MapTheme }}
    defaultZoom={10}
    defaultCenter={{
      lat: parseFloat(props.property.lat),
      lng: parseFloat(props.property.long),
    }}
  >
    <Marker
      label={{
        color: "black",
        fontWeight: "bold",
        text: props.property.title,
      }}
      position={{
        lat: parseFloat(props.property.lat),
        lng: parseFloat(props.property.long),
      }}
    />
  </GoogleMap>
))

const Property = ({ data }) => {
  const property = data.markdownRemark.frontmatter
  console.log(property)
  const markdownHtml = data.markdownRemark.html
  return (
    <Layout>
      <div className="relative bg-white py-16 sm:py-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className="relative sm:py-16 lg:py-0">
            <div
              aria-hidden="true"
              className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
            >
              <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={392}
                  fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                />
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              {/* Testimonial card*/}
              <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src={data.allFile.edges[0].node.publicURL}
                  alt=""
                />
                <div className="absolute inset-0 bg-indigo-500 mix-blend-multiply opacity-60" />
                <div className="absolute inset-0 bg-gradient-to-t from-indigo-600 via-indigo-600 opacity-40" />
                <div className="relative px-8">
                  <div className=" text-white flex flex-col">
                    <span className=" font-semibold text-2xl">
                      {property.title}
                    </span>
                    <span>
                      {property.city}, {property.state}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <span className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                {property.title}
              </span>
              <div className="mt-6 text-gray-500 space-y-6">
                <div
                  className="blog-post-content"
                  dangerouslySetInnerHTML={{ __html: markdownHtml }}
                />
              </div>

              {property.unitBreakdown && (
                <div className="mt-10">
                  <div className="border-t-2 border-gray-100 pt-6">
                    <dt className="text-base font-medium text-gray-500">
                      {property.unitBreakdown[0].type}
                    </dt>
                    <dd className="text-3xl font-extrabold tracking-tight text-gray-900">
                      {property.unitBreakdown[0].value}
                    </dd>
                  </div>
                  <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                    {property.unitBreakdown.slice(1).map(breakdown => (
                      <div
                        key={breakdown.type}
                        className="border-t-2 border-gray-100 pt-6"
                      >
                        <dt className="text-base font-medium text-gray-500">
                          {breakdown.type}
                        </dt>
                        <dd className="text-3xl font-extrabold tracking-tight text-gray-900">
                          {breakdown.value}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-5">
          <ImageCarousel imageNodes={data.allFile.edges} />
        </div>
        <div className="mt-5">
          <PropertyMap
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            property={property}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Property

export const propertyQuery = graphql`
  query propertyQuery($slug: String!, $relativeDirectory: String!) {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativeDirectory: { eq: $relativeDirectory }
      }
      sort: { fields: relativePath }
    ) {
      totalCount
      edges {
        node {
          relativePath
          publicURL
        }
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        roomCount
        city
        state
        lat
        long
        unitBreakdown {
          type
          value
        }
      }
    }
  }
`
